module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Nordter Trauma Radiology 2024","short_name":"Traumarad 2024","start_url":"/","background_color":"#18181b","theme_color":"#bf432c","display":"minimal-ui","icon":"src/images/Nordter_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"10d3c2cd4a6574562ed8df79d9d3cc0c"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
